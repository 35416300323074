import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { createClient, ClientConfig } from "next-sanity";
import { type FilterDefault } from "@sanity/client";

const encodeSourceMapAtPath: FilterDefault = (props) => {
  if (
    [
      "tag",
      "sku",
      "imageThemeFade1",
      "imageThemeFade2",
      "theme",
      "background",
      "simpleBackground",
      "shortSection",
      "variant",
      "contentAlignment",
    ].includes(`${props.sourcePath[0]}`)
  ) {
    return false;
  }
  // The default behavior is packaged into `filterDefault`, allowing you enable encoding fields that are skipped by default
  return props.filterDefault(props);
};

export const getClient = ({
  projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ?? "unknown",
  dataset = process.env.NEXT_PUBLIC_SANITY_DATASET ?? "development",
  apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION ?? "2022-11-16",
  useCdn = true,
  stega = {
    enabled: process.env.VERCEL_ENV === "preview",
    studioUrl: "/cms-studio",
    filter: encodeSourceMapAtPath,
  },
  token = undefined,
  perspective = "published",
}: ClientConfig) =>
  createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn,
    token,
    perspective,
    stega,
  });

export const client = getClient({
  useCdn: false,
});
export const productClient = getClient({});

const imageBuilder = imageUrlBuilder(client);
export const imageUrlFor = (source: SanityImageSource) =>
  imageBuilder.image(source);
