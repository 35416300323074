/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Image from "next/image";
import { sanityFetch } from "@/cms";
import groq from "groq";
import { SanityImage } from "@/cms/images/types";

export default async function NotFoundImage() {
  const data = await sanityFetch<{ notFoundImage?: SanityImage }[]>({
    query: groq`*[_type=="system"]{
      notFoundImage{
              asset->
          },}`,
    tags: ["system"],
  });

  return (
    <div>
      {data?.[0]?.notFoundImage?.asset?.url && (
        <Image
          className="w-[100%] max-w-[450px] object-cover sm:w-[auto]"
          src={data[0].notFoundImage.asset.url}
          alt="404"
          width={300}
          height={300}
        />
      )}
    </div>
  );
}

export const NotFoundImageLoading = () => (
  <div className="h-[300px] w-[300px] bg-gray-50" />
);
